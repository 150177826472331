import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserHome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaUserFriends, FaExchangeAlt, FaPlusCircle, FaListUl, FaSignOutAlt, FaShareAlt } from 'react-icons/fa';
import { Form, Button, CloseButton, Tab, Nav } from 'react-bootstrap';
import config from '../config';
import { QRCodeSVG } from 'qrcode.react';

import WavesBackground from './components/Waves';

const UserHome = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [userDetails, setUserDetails] = useState({
    string_id: '',
    balance: 0,
    is_active: false,
    created_at: '',
    updated_at: '',
    nickname: '',
  });
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [usersWithNickname, setUsersWithNickname] = useState([]);
  const [friendsList, setFriendsList] = useState([]);
  const [incomingFriendRequests, setIncomingFriendRequests] = useState([]);
  const [sentFriendRequests, setSentFriendRequests] = useState([]);
  const [allUsersList, setAllUsersList] = useState([]); // Add this state variable
  const navigate = useNavigate();
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [amount, setAmount] = useState(0);
  const amountInputRef = useRef(null);
  const contentRef = useRef(null);  // Ref for the content div
  const bottomBarRef = useRef(null); // Ref for the bottom bar div

  const [transactions, setTransactions] = useState({ from_transactions: [], to_transactions: [] });
  const [transactionsLoading, setTransactionsLoading] = useState(false);

  const [sendingAmount, setSendingAmount] = useState('');
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const fetchAllUsers = async () => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      return;
    }

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/users/?skip=0&limit=1000`, {
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });

      if (response.ok) {
        const data = await response.json();
        const filteredUsers = data.filter(user => user.string_id !== storedStringId);
        console.log('All users:', response.json); // Add this line
        setAllUsersList(filteredUsers);
      } else if (response.status === 401) {
        alert('Error: Unauthorized. Please check your credentials.');
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch all users', errorData);
      }
    } catch (error) {
      console.error('Error fetching all users:', error);
    }
  };

  const fetchUserDetails = async () => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      setLoading(false);
      navigate('/');
      return;
    }
    setLoading(true); // Show loading while fetching

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/users/me`, {
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });
      if (response.ok) {
        const data = await response.json();
        setUserDetails(data);
      } else if (response.status === 401) {
        alert('Error: Unauthorized. Please check your credentials.');
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch user details', errorData);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setLoading(false);
    }
  };

  const sendFriendRequest = async (friendId) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');
    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: storedStringId, friend_id: friendId }),
      });
      if (response.ok) {
        alert('Friend request sent!');
        fetchSentFriendRequests(); // Refresh the sent requests
      } else {
        const errorData = await response.json();
        console.error('Failed to send friend request:', errorData);
      }
    } catch (error) {
      console.error('Error sending friend request:', error);
    }
  };

  const fetchFriends = async () => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends/${storedStringId}`, {
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });

      if (response.ok) {
        const data = await response.json();
        setFriendsList(data);
      } else {
        console.error('Failed to fetch friends');
      }
    } catch (error) {
      console.error('Error fetching friends:', error);
    }
  };

  const fetchIncomingFriendRequests = async () => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friend_requests/received/${storedStringId}`, {
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });

      if (response.ok) {
        const data = await response.json();
        setIncomingFriendRequests(data);
      } else {
        console.error('Failed to fetch incoming friend requests');
      }
    } catch (error) {
      console.error('Error fetching incoming friend requests:', error);
    }
  };

  const fetchSentFriendRequests = async () => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friend_requests/sent/${storedStringId}`, {
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });

      if (response.ok) {
        const data = await response.json();
        setSentFriendRequests(data);
      } else {
        console.error('Failed to fetch sent friend requests');
      }
    } catch (error) {
      console.error('Error fetching sent friend requests:', error);
    }
  };

  const handleSendFriendRequest = async (friendId) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');
    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'Content-Type': 'application/json',
          'vx': config.VX
        },
        body: JSON.stringify({ user_id: storedStringId, friend_id: friendId }),
      });
      if (response.ok) {
        alert('Friend request sent successfully!');
        fetchSentFriendRequests(); // Refresh the sent requests
      } else {
        const errorData = await response.json();
        console.error('Failed to send friend request:', errorData);
      }
    } catch (error) {
      console.error('Error sending friend request:', error);
    }
  };


  const handleAcceptFriendRequest = async (friendId) => {
    const storedStringId = localStorage.getItem('stringId');
    const status = 'accepted'
    const storedPw = localStorage.getItem('pw');

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends/${storedStringId}/${friendId}/${status}`, {
        method: 'PATCH',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });

      if (response.ok) {
        fetchIncomingFriendRequests();
        fetchFriends();
      } else {
        console.error('Failed to accept friend request');
      }
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  const handleCancelFriendRequest = async (friendId) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends/${storedStringId}/${friendId}`, {
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });

      if (response.ok) {
        fetchSentFriendRequests();
      } else {
        console.error('Failed to cancel friend request');
      }
    } catch (error) {
      console.error('Error canceling friend request:', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    fetchFriends();
    fetchIncomingFriendRequests();
    fetchSentFriendRequests();
  }, []);

  const [qrCodeUrl, setQrCodeUrl] = useState('');

  useEffect(() => {
    const url = `${config.baseURL_FE}/payment/verify?userId=${localStorage.getItem('stringId')}&amount=${sendingAmount}`;
    setQrCodeUrl(url);
  }, [sendingAmount]);

  useEffect(() => {
    const fetchUsersWithNickname = async () => {
      const storedStringId = localStorage.getItem('stringId');
      const storedPw = localStorage.getItem('pw');

      if (!storedStringId || !storedPw) {
        console.error('No credentials found in local storage');
        return;
      }

      try {
        const response = await fetch(`${config.baseURL_BE}/v2/users/?skip=0&limit=1000`, {
          headers: {
            'accept': 'application/json',
            'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
            'vx': config.VX
          }
        });
        if (response.ok) {
          const data = await response.json();
          const usersWithNicknameFiltered = data.filter(user => user.nickname && user.string_id !== localStorage.getItem('stringId'));
          setUsersWithNickname(usersWithNicknameFiltered);
        } else if (response.status === 401) {
          loginCredentialsFailedOnAPI(navigate);
        } else {
          const errorData = await response.json();
          console.error('Failed to fetch users with nickname', errorData);
        }
      } catch (error) {
        console.error('Error fetching users with nickname:', error);
      }
    };

    fetchUsersWithNickname();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setShowPopup(false);
    setPopupContent(null);
    setUserDetails(prevState => ({ ...prevState, nickname: '' }));
    navigate('/');
  };

  const handleClosePopup = (returnToTransfer = false) => {
    if (returnToTransfer) {
      showTransferPopup();
    } else {
      setShowPopup(false);
      setPopupContent(null);
    }
  };

  const showTransferPopup = () => {
    setPopupContent(
      <div className="popup-inner">
        <div className="popup-header">
          <h4>Transfer</h4>
          <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
        </div>
        <div className="d-flex align-items-center">
          <div className="w-100">
            <label>XID:</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input type="text" />
              <button className="btn btn-icon ms-2" onClick={showXwipersPopup}><FaUserFriends /></button>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <label>XWPC:</label>
          <input type="text" className="amount-input" />
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button className="btn btn-cyberpunk">SEND</button>
          <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk">CANCEL</button>
        </div>
      </div>
    );
    setShowPopup(true);
  };

  const fetchTransactions = async (callback) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      return;
    }

    setTransactionsLoading(true);

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/transactions/`, {
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched transactions:', data); // Log the fetched transactions
        setTransactions(() => ({
          from_transactions: data.from_transactions,
          to_transactions: data.to_transactions
        }));
        if (callback) callback(data.from_transactions, data.to_transactions);
      } else if (response.status === 401) {
        loginCredentialsFailedOnAPI(navigate);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch transactions', errorData);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setTransactionsLoading(false);
    }
  };

  const handleNicknameSubmit = async (nickname) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    try {
      setFormLoading(true);
      console.log(config.baseURL_BE);
      const response = await fetch(`${config.baseURL_BE}/v2/users/me`, {
        method: 'PATCH',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'Content-Type': 'application/json',
          'vx': config.VX
        },
        body: JSON.stringify({ nickname })
      });

      if (response.ok) {
        const updatedUserDetails = { ...userDetails, nickname };
        setUserDetails(updatedUserDetails);
        showTransferPopup();
      } else if (response.status === 401) {
        loginCredentialsFailedOnAPI(navigate);
      } else {
        const errorData = await response.json();
        console.error('Failed to update nickname', errorData);
      }
    } catch (error) {
      console.error('Error updating nickname:', error);
    } finally {
      setFormLoading(false);
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      const nickname = event.target.value;
      handleNicknameSubmit(nickname);
    }
  };

  // Add this helper function to copy the URL to the clipboard
  const handleShareClick = () => {
    navigator.clipboard.writeText(qrCodeUrl).then(() => {
      setShowCopyNotification(true);
      setTimeout(() => {
        setShowCopyNotification(false);
      }, 5000); // Hide the notification after 2 seconds
    }).catch(err => console.error('Failed to copy URL:', err));
  };

  const showXwipersPopup = () => {
    const handleFriendClick = (user) => {
      setSelectedFriend(user);
      setAmount(0); // Reset amount when a new friend is selected
      var newAmount2 = 0;
      setPopupContent(
        <div className="popup-inner">
          <div className="popup-header">
            <h4>Send XPWC to {user.nickname}</h4>
            <button className="close-btn btn-icon" onClick={() => handleClosePopup(true)}>&times;</button>
          </div>
          <div>
            <Form onSubmit={(e) => { e.preventDefault(); handleSendAmount(user, newAmount2 * 100); }}>
              <Form.Group className="mb-3" controlId="inputAmount">
                <input
                  type="text"
                  onChange={(e) => {
                    newAmount2 = e.target.value;
                  }}
                />
                <Form.Text className="text-muted">
                  dots and digits only, please don't enter characters
                </Form.Text>
              </Form.Group>
              <div className="d-flex justify-content-between mt-3">
                <Button variant="primary" type="submit" className="btn btn-cyberpunk">
                  Submit
                </Button>
                <CloseButton onClick={() => handleClosePopup(true)} className="btn btn-cyberpunk">Cancel</CloseButton>
              </div>
            </Form>
          </div>
        </div>
      );
      setShowPopup(true);
    };

    const content = (
      <div className="popup-inner xwipers-popup">
        <div className="popup-header">
          <h4>Xwipers</h4>
          <button className="close-btn btn-icon" onClick={() => handleClosePopup(true)}>&times;</button>
        </div>
        <ul className="xwipers-list">
          {usersWithNickname.map(user => (
            <React.Fragment key={user.string_id}>
              <li onClick={() => handleFriendClick(user)} className="clickable-friend">{user.nickname}</li>
              <div className="cyberpunk-bar"></div>
            </React.Fragment>
          ))}
        </ul>
      </div>
    );
    setPopupContent(content);
    setShowPopup(true);
  };

  const handleSendAmount = async (user, newAmount2) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      return;
    }

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/transactions/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'Content-Type': 'application/json',
          'vx': config.VX
        },
        body: JSON.stringify({
          amount: parseInt(newAmount2, 10),
          to_string_id: user.string_id
        })
      });

      if (response.ok) {
        const result = await response.json();
        fetchUserDetails();
      } else if (response.status === 401) {
        loginCredentialsFailedOnAPI(navigate);
      } else if (response.status == 402) {
        // Handle insufficient funds
        alert('Error: Insufficient funds. Please check your balance.');
      } else if (response.status == 403) {
        // Handle invalid amount
        alert('Error: Invalid amount. Please enter a valid amount.');
      } else {
        const errorData = await response.json();
        console.error('Failed to send amount', errorData);
      }

    } catch (error) {
      console.error('Error sending amount:', error);
    }

    handleClosePopup(); // Close the popup after sending
  };

  useEffect(() => {
    if (showPopup && amountInputRef.current) {
      amountInputRef.current.focus();
    }
  }, [showPopup]);

  const handleDonate = async () => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      return;
    }

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/donate/?user_id=${storedStringId}`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        },
        body: ''
      });

      if (response.ok) {
        const result = await response.json();
        fetchUserDetails();
      } else if (response.status === 401) {
        loginCredentialsFailedOnAPI(navigate);
      } else {
        const errorData = await response.json();
        console.error('Failed to donate', errorData);
      }
    } catch (error) {
      console.error('Error making donation:', error);
    }

    handleClosePopup(); // Close the popup after donating
  };

  const handleButtonClick = async (type) => {
    let content = null;
    switch (type) {
      case 'transfer':
        if (!userDetails.nickname) {
          content = (
            <div className="popup-inner">
              <div className="popup-header">
                <h4>Enter Nickname</h4>
                <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
              </div>
              <div>
                <label>Nickname:</label>
                <input type="text" id="nickname-input" onKeyPress={handleEnterKeyPress} />
              </div>
              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-cyberpunk"
                  onClick={() => {
                    const nickname = document.getElementById('nickname-input').value;
                    handleNicknameSubmit(nickname);
                  }}
                  disabled={formLoading}
                >
                  {formLoading ? 'Loading...' : 'SUBMIT'}
                </button>
                <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk">CANCEL</button>
              </div>
            </div>
          );
        } else {
          showTransferPopup();
          return;
        }
        break;
      case 'add-xwpc':
        content = (
          <div className="popup-inner">
            <div className="popup-header">
              <h4>Donate XWPC</h4>
              <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <button className="btn btn-cyberpunk" onClick={handleDonate}>DONATE</button>
              <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk">CANCEL</button>
            </div>
          </div>
        );
        break;
      case 'friends':
        fetchAllUsers(); // Ensure this is being called to fetch all users
        content = (
          <div className="popup-inner">
            <div className="popup-header">
              <h4>Friends</h4>
              <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
            </div>
            <Tab.Container defaultActiveKey="friends">
              <Nav variant="pills" className="mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="friends">Friends</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="incoming">Incoming Requests</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sent">Sent Requests</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="all">All Users</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="friends">
                  <ul>
                    {friendsList.map(friend => (
                      <li key={friend.friend_id}>
                        {friend.friend_nickname}
                      </li>
                    ))}
                  </ul>
                </Tab.Pane>
                <Tab.Pane eventKey="incoming">
                  <ul>
                    {incomingFriendRequests.map(request => (
                      <li key={request.user_id}>
                        {request.friend_nickname}
                        <button onClick={() => handleAcceptFriendRequest(request.user_id)}>Accept</button>
                      </li>
                    ))}
                  </ul>
                </Tab.Pane>
                <Tab.Pane eventKey="sent">
                  <ul>
                    {sentFriendRequests.map(request => (
                      <li key={request.friend_id}>
                        {request.friend_nickname}
                        <button onClick={() => handleCancelFriendRequest(request.friend_id)}>Cancel</button>
                      </li>
                    ))}
                  </ul>
                </Tab.Pane>
                <Tab.Pane eventKey="all"> {/* All Users Tab */}
                  <ul>
                    {allUsersList.length === 0 ? (
                      <li>No users found</li>
                    ) : (
                      allUsersList.map(user => (
                        <li key={user.string_id}>
                          {user.nickname || "Unnamed User"} {/* Handle unnamed users */}
                          <button onClick={() => handleSendFriendRequest(user.string_id)}>Send Request</button>
                        </li>
                      ))
                    )}
                  </ul>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        );
        break;
      case 'trxs':
        setTransactionsLoading(true); // Set transactions loading state to true
        fetchTransactions((from_transactions, to_transactions) => {
          setTransactionsLoading(false); // Set transactions loading state to false
          setPopupContent(() => (
            <div className="popup-inner">
              <div className="popup-header">
                <h4>Transactions</h4>
                <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
              </div>
              <Tab.Container defaultActiveKey="sent">
                <Nav variant="pills" className="mb-3">
                  <Nav.Item>
                    <Nav.Link eventKey="sent">Sent</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="received">Received</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="sent">
                    <div className="cyberpunk-bar"></div>
                    <ul>
                      {from_transactions.map(tx => (
                        <li key={tx.id}>
                          <div>
                            XPWC: <strong>${(tx.amount / 100).toFixed(2)}</strong> <br />
                            To: <strong>{tx.to_nickname}</strong> <br />
                            Date: <strong>{new Date(tx.created_at).toLocaleString()}</strong>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Tab.Pane>
                  <Tab.Pane eventKey="received">
                    <div className="cyberpunk-bar"></div>
                    <ul>
                      {to_transactions.map(tx => (
                        <li key={tx.id}>
                          <div>
                            From: <strong>{tx.from_nickname}</strong> <br />
                            Amount: <strong>${(tx.amount / 100).toFixed(2)}</strong> <br />
                            Date: <strong>{new Date(tx.created_at).toLocaleString()}</strong>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
              <div className="d-flex justify-content-end">
                <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk mt-3">CANCEL</button>
              </div>
            </div>
          ));
          setShowPopup(true);
        });

        // Show loading content while fetching transactions
        setPopupContent(() => (
          <div className="popup-inner">
            <div className="popup-header">
              <h4>Loading Transactions...</h4>
              <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
            </div>
            <div className="d-flex justify-content-center align-items-center vh-100">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        ));
        setShowPopup(true);
        return;
      case 'logout':
        content = (
          <div className="popup-inner">
            <h4>Are you sure?</h4>
            <p>User data might be lost if you aren't already signed in on another device.</p>
            <div className="d-flex justify-content-between">
              <button onClick={handleLogout} className="btn btn-cyberpunk">Yes, Logout</button>
              <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk">No, Cancel</button>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
    setPopupContent(content);
    setShowPopup(true);
  };

  useEffect(() => {
    const adjustContentHeight = () => {
      if (contentRef.current && bottomBarRef.current) {
        const bottomBarHeight = bottomBarRef.current.offsetHeight;
        contentRef.current.style.height = `calc(100vh - ${bottomBarHeight}px)`;
      }
    };

    adjustContentHeight();
    window.addEventListener('resize', adjustContentHeight);

    return () => {
      window.removeEventListener('resize', adjustContentHeight);
    };
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  function AccountInfo({ userDetails }) {
    // Get the account creation date and today's date
    const createdDate = new Date(userDetails.created_at);
    const today = new Date();

    // Calculate the difference in days
    const timeDifference = today - createdDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return (
      <div>
        <p className="account-info mb-1">
          {daysDifference} great days of using xwiped
        </p>
        {/* Other account info */}
      </div>
    );
  }

  function loginCredentialsFailedOnAPI(navigate) {
    console.error('Unauthorized: Check your credentials');
    alert('Error: Unauthorized. Please check your credentials.');
    navigate('/');
  }

  return (
    <div className="vh-100 justify-content-center align-items-center">
      <WavesBackground />
      <div className="flex-column justify-content-between align-items-center vh-100 d-flex bg-dark text-white">
        <div className="top-bar">
          {userDetails.nickname && <span className="nickname">{userDetails.nickname}</span>}
          <div>XPWC: <strong>${(userDetails.balance / 100).toFixed(2)}</strong></div>
        </div>

        {showPopup && (
          <div className="popup">
            {popupContent}
          </div>
        )}

        <div className="container text-center special-text-container-userhome" ref={contentRef}>
          <div className="col">
            <div className="row mt-5">   </div>
            <div className="row mt-5">   </div>
            <div className="row mt-5">
              {/* <h1 className="welcome-message mb-3">Welcome Home</h1> */}
              <h1 className="welcome-message mb-3">Welcome {userDetails.nickname}</h1>
              {AccountInfo({ userDetails })}
            </div>
            <div className="row mt-5">
              <div>
                <input
                  type="number"
                  onChange={(e) => {
                    setSendingAmount(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row mt-5">
              <div>
                <a href={qrCodeUrl} target="_blank" rel="noopener noreferrer">
                  {qrCodeUrl && (
                    <QRCodeSVG value={qrCodeUrl} size={256} includeMargin={true} />
                  )}
                </a>
              </div>
            </div>
            <div className="row mt-5">
              {/* <button className="btn btn-icon" onClick={handleShareClick}>
                <FaShareAlt size={24} />
                <div>SHARE</div>
              </button> */}
            </div>

            <div className="row mt-5">
              {showCopyNotification && (
                <div className="copy-notification">Link copied to clipboard</div>
              )}

              <button className="btn btn-cyberpunk-centered" onClick={handleShareClick}>
                <FaShareAlt size={24} />
                <div>SHARE</div>
              </button>
              {/* <br />which one is better? */}
            </div>
          </div>
        </div>

        <div className="bottom-bar" ref={bottomBarRef}>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('transfer')}>
            <FaExchangeAlt size={24} />
            <div>TRANSFER</div>
          </button>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('add-xwpc')}>
            <FaPlusCircle size={24} />
            <div>ADD XPWC</div>
          </button>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('trxs')}>
            <FaListUl size={24} />
            <div>TRXs</div>
          </button>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('friends')}>
            <FaUserFriends size={24} />
            <div>FRIENDS</div>
          </button>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('logout')}>
            <FaSignOutAlt size={24} />
            <div>LOGOUT</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserHome;
