// src/components/CaretField.js
import React, { useRef, useEffect, useMemo } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

function createCaretGeometry_old() {
  const dx = 0.1; // Half the width of the caret at base
  const dy = 0.25; // Height of the caret

  const shape = new THREE.Shape();
  shape.moveTo(-dx, 0);
  shape.lineTo(0, dy);
  shape.lineTo(dx, 0);
  shape.closePath();

  const geometry = new THREE.ShapeGeometry(shape);
  return geometry;
}

function createCaretGeometry() {
  const dx = 0.3; // Adjust size as needed
  const dy = 0.3;

  const shape = new THREE.Shape();
  const outerRadius = dx;
  const innerRadius = dx / 2;
  const numPoints = 5;

  for (let i = 0; i < numPoints * 2; i++) {
    const angle = (i / (numPoints * 2)) * Math.PI * 2;
    const radius = i % 2 === 0 ? outerRadius : innerRadius;
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * radius;
    if (i === 0) {
      shape.moveTo(x, y);
    } else {
      shape.lineTo(x, y);
    }
  }
  shape.closePath();

  const geometry = new THREE.ShapeGeometry(shape);
  return geometry;
}

function createComplexGeometry() {
  // Example: Torus Knot Geometry
  const geometry = new THREE.TorusKnotGeometry(0.2, 0.05, 100, 16);
  return geometry;
}

function CaretField() {
  const countX = 200; // Number of carets along X
  const countZ = 200; // Number of carets along Z
  const totalCount = countX * countZ;
  const spacing = 0.79;

  const waveFrequency = 0.15;
  const waveAmplitude = 0.25;

  const windFrequency = 0.1;

  const speed = 0.75;

  const caretGeometry = useMemo(() => createCaretGeometry(), []);
  //  const complexGeometry = useMemo(() => createComplexGeometry(), []);
  const material = useMemo(
    () =>
      new THREE.MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.75,
        side: THREE.DoubleSide,
      }),
    []
  );

  const meshRef = useRef();

  const dummy = useMemo(() => new THREE.Object3D(), []);

  const colors = useMemo(() => new Float32Array(totalCount * 3), [totalCount]);

  // Initialize instance matrices and colors
  useEffect(() => {
    const mesh = meshRef.current;
    if (!mesh) return;

    for (let i = 0; i < totalCount; i++) {
      const x = (i % countX) - countX / 2;
      const z = Math.floor(i / countX) - countZ / 2;

      dummy.position.set(x * spacing, 0, z * spacing);
      dummy.rotation.x = -Math.PI / 2; // Rotate carets to face upward
      dummy.updateMatrix();

      mesh.setMatrixAt(i, dummy.matrix);

      // Initial color (white)
      colors[i * 3 + 0] = 1;
      colors[i * 3 + 1] = 1;
      colors[i * 3 + 2] = 1;
    }

    mesh.instanceMatrix.needsUpdate = true;

    // Add per-instance color attribute
    mesh.instanceColor = new THREE.InstancedBufferAttribute(colors, 3);
  }, [colors, countX, countZ, spacing, totalCount, dummy]);

  // Animate the carets
  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    const mesh = meshRef.current;
    if (!mesh) return;

    for (let i = 0; i < totalCount; i++) {
      const xIndex = i % countX;
      const zIndex = Math.floor(i / countX);

      const x = xIndex - countX / 2;
      const z = zIndex - countZ / 2;

      // Compute wave displacement
      // const y =
      //   Math.sin((x * waveFrequency + time) * 0.5) *
      //   waveAmplitude *
      //   Math.cos((z * waveFrequency + time) * 0.5);
      const y = Math.sin((x * waveFrequency + z * waveFrequency + time * speed)) * waveAmplitude;

      dummy.position.set(x * spacing, y, z * spacing - 38);
      dummy.rotation.x = -Math.PI / 2; // Ensure the caret continues to face upward

      dummy.updateMatrix();
      mesh.setMatrixAt(i, dummy.matrix);


      // Update color based on wind effect
      const wind =
        Math.sin((z * windFrequency + time) * 0.5) * 0.5 + 0.5; // Value between 0 and 1

      // RGB values for Deep Navy Blue (#1A237E)
      const r1 = 26 / 255;
      const g1 = 35 / 255;
      const b1 = 126 / 255;

      // RGB values for Soft Sky Blue (#90CAF9)
      const r2 = 144 / 255;
      const g2 = 202 / 255;
      const b2 = 249 / 255;

      // Interpolate between the two colors
      colors[i * 3 + 0] = (1 - wind) * r1 + wind * r2; // Red channel
      colors[i * 3 + 1] = (1 - wind) * g1 + wind * g2; // Green channel
      colors[i * 3 + 2] = (1 - wind) * b1 + wind * b2; // Blue channel

    }

    mesh.instanceMatrix.needsUpdate = true;
    mesh.instanceColor.needsUpdate = true;
  });

  // return (
  //   <instancedMesh
  //     ref={meshRef}
  //     args={[complexGeometry, material, totalCount]}
  //   />
  // );
  return (
    <instancedMesh
      ref={meshRef}
      args={[caretGeometry, material, totalCount]}
    />
  );
}

function Scene() {
  return (
    <>
      <CaretField />
    </>
  );
}

export default function WavesBackground() {
  return (
    <Canvas
      camera={{ fov: 20, position: [5, 5, 42], near: 0.1, far: 1000 }}
      style={{ backgroundColor: 'black' }}
    >
      <ambientLight intensity={0.5} />
      <Scene />
      <OrbitControls />
    </Canvas>
  );
}
