import React from 'react';
import { useLocation } from 'react-router-dom';
import config from '../config';
import EarthBackground from './components/RotatingSphere'; // Import the 3D Earth component
import './styles/ApprovePayment.css';
import { Link } from 'react-router-dom';

import ThreeScene from './components/ThreeScene';
import SignupLoginButtons from './components/SignupLoginButtons';


const ApprovePayment = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const amount = queryParams.get('amount');
    const userId = queryParams.get('userId');

    const handleConfirm = async () => {
        try {
            const storedStringId = localStorage.getItem('stringId');
            const storedPw = localStorage.getItem('pw');

            const response = await fetch(`${config.baseURL_BE}/v2/transactions/`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
                    'Content-Type': 'application/json',
                    'vx': config.VX
                },
                body: JSON.stringify({
                    amount: parseInt(100 * amount, 10),
                    to_string_id: userId,
                }),
            });

            if (response.ok) {
                const result = await response.json();
                window.location.href = '/home';
            } else if (response.status === 401) {
                console.error('Unauthorized: Check your credentials');
            } else {
                const errorData = await response.json();
                console.error('Failed to send amount', errorData);
            }
        } catch (error) {
            console.error('Error sending amount:', error);
        }
    };

    return (
        <div>
            <div className="position-relative vh-100 d-flex justify-content-center align-items-center">
                <EarthBackground />
                <div className="container special-text-container">
                    <div className='col text-white'>
                        <div className='row-sm'>
                            <h1 className="mb-4">Approve Payment</h1>
                        </div>
                        <div className='row-sm'>
                            <strong>{amount}</strong>
                            <span className="fs-6 ms-2">XPWC</span>
                        </div>
                        <div className='row-sm'>
                            <strong>User ID:</strong> {userId}
                        </div>
                    </div>

                </div>
                <div className="container button-container text-center">
                    <Link to="/sign-up" className="btn btn-custom btn-custom-signup mb-3">CANCEL</Link>
                    {/* <Link to="/login" className="btn btn-custom btn-custom-login mb-3">CONFIRM</Link> */}
                    <button className="btn btn-custom btn-custom-login mb-3" onClick={handleConfirm}>
                        CONFIRM
                    </button>
                </div>
            </div>

        </div>

    );
};

export default ApprovePayment;
